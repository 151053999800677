// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bangla-newspapers-js": () => import("./../../../src/pages/bangla-newspapers.js" /* webpackChunkName: "component---src-pages-bangla-newspapers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-english-newspapers-js": () => import("./../../../src/pages/english-newspapers.js" /* webpackChunkName: "component---src-pages-english-newspapers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-newspapers-js": () => import("./../../../src/pages/international-newspapers.js" /* webpackChunkName: "component---src-pages-international-newspapers-js" */),
  "component---src-pages-tv-channels-js": () => import("./../../../src/pages/tv-channels.js" /* webpackChunkName: "component---src-pages-tv-channels-js" */),
  "component---src-templates-newspaper-js": () => import("./../../../src/templates/newspaper.js" /* webpackChunkName: "component---src-templates-newspaper-js" */),
  "component---src-templates-newspaper-view-js": () => import("./../../../src/templates/newspaper-view.js" /* webpackChunkName: "component---src-templates-newspaper-view-js" */)
}

